import { ICustomImage, ICustomImageAsset } from "@filmweb/sanity-types";
import { BaseCmsImageProps } from 'src/components/images/BaseCmsImageProps';
import CmsImageForAssetRef from 'src/components/images/CmsImageForAssetRef';
import type { Image, ImageHotspot } from 'sanity';

//#region [Props]
type CmsImageProps = {
	image: Image | ICustomImage;
} & BaseCmsImageProps;
//#endregion

//#region [Component]
export default function CmsImage({ image, ...rest }: CmsImageProps) {
	if (!image) {
		return null;
	}

	const assetRef: string | undefined = (image!.asset?._ref as string | undefined ?? (image!.asset as ICustomImageAsset)?._id);
	if (!assetRef) {
		return null;
	}

	return <CmsImageForAssetRef assetRef={assetRef} hotspot={image.hotspot as ImageHotspot} {...rest} />
}
//#endregion
