import { StandardSvgProps } from 'src/components/svg/StandardSvgProps';

//#region [Props]
type SGNewsPageWithBlingSvgProps = {
} & StandardSvgProps;
//#endregion

//#region [Component]
export default function SGNewsPageWithBling({ className }: SGNewsPageWithBlingSvgProps) {
	return (<svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.917 55.631">
		<defs>
			<linearGradient id="linear-newsWithBling" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
				<stop offset="0" stopColor="var(--custom-gradient-stop0)" />
				<stop offset="1" stopColor="var(--custom-gradient-stop1)" />
			</linearGradient>
		</defs>
		<path d="M2111.53,3543.094h29.095a2.53,2.53,0,0,1,2.53,2.53v33.523a2.53,2.53,0,0,1-2.53,2.53H2111.53a2.53,2.53,0,0,1-2.53-2.53v-33.523A2.53,2.53,0,0,1,2111.53,3543.094Zm2.53,3.163h24.035a2.53,2.53,0,0,1,2.53,2.53v7.59a2.53,2.53,0,0,1-2.53,2.53H2114.06a2.53,2.53,0,0,1-2.53-2.53v-7.59A2.53,2.53,0,0,1,2114.06,3546.256Zm0,15.813h8.223a2.53,2.53,0,0,1,2.53,2.53v3.8a2.53,2.53,0,0,1-2.53,2.53h-8.223a2.53,2.53,0,0,1-2.53-2.53v-3.8A2.53,2.53,0,0,1,2114.06,3562.069Zm15.18,0h9.488a1.9,1.9,0,0,1,0,3.794h-9.488a1.9,1.9,0,1,1,0-3.794Zm0,5.06h9.488a1.9,1.9,0,0,1,0,3.795h-9.488a1.9,1.9,0,1,1,0-3.795Zm24.4-28.216q-6.623.265-6.9,6.868a.278.278,0,1,1-.557,0q-.275-6.593-6.894-6.875a.275.275,0,0,1,0-.55q6.622-.275,6.9-6.875a.276.276,0,0,1,.551,0q.275,6.6,6.9,6.881a.275.275,0,0,1,0,.551Zm-9.532-7.749q-4.431.18-4.617,4.592a.183.183,0,0,1-.367,0q-.181-4.412-4.611-4.6a.184.184,0,0,1,0-.367q4.431-.179,4.611-4.592a.19.19,0,0,1,.373,0q.18,4.412,4.611,4.6a.183.183,0,0,1,0,.367Z"
			transform="translate(-2109 -3526.044)"
			fillRule="evenodd"
			fill="url(#linear-newsWithBling)" />
	</svg>)
}
//#endregion