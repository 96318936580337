import { StandardSvgProps } from 'src/components/svg/StandardSvgProps';

//#region [Props]
type SGTrophySvgProps = {
} & StandardSvgProps;
//#endregion

//#region [Component]
export default function SGTrophy({ className }: SGTrophySvgProps) {
	return (<svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.412 35.276">
		<defs>
			<linearGradient id="linear-gradientTrophy" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
				<stop offset="0" stopColor="var(--custom-gradient-stop0)" />
				<stop offset="1" stopColor="var(--custom-gradient-stop1)" />
			</linearGradient>
		</defs>
		<path d="M3623.2,1855.758a1.687,1.687,0,0,0-1.563-1.115H3616.7c-.013-.848-.055-1.352-.055-1.352h-23.01s-.039.5-.05,1.352h-4.938a1.688,1.688,0,0,0-1.565,1.115c-.671,1.747,1.012,8.649,4.059,11.444a7.419,7.419,0,0,0,3.986,1.885c.419.148.741.471.71.725-.055.424-.84.5-.512,1.326.366.923,2.381.458,2.691-1.014a10.631,10.631,0,0,0,4,2.853c2.1.853.626,9.871.626,10.8,0,0-3.354,0-3.947.895a1.991,1.991,0,0,0-.3,1.194s-2.168.7-2.168,1.591v1.108h17.825v-1.108c0-.895-2.171-1.591-2.171-1.591a1.994,1.994,0,0,0-.3-1.194c-.593-.895-3.947-.895-3.947-.895,0-.931-1.477-9.949.626-10.8a10.655,10.655,0,0,0,4-2.853c.31,1.472,2.323,1.937,2.689,1.014.33-.829-.458-.9-.512-1.326-.032-.255.294-.577.709-.725a7.416,7.416,0,0,0,3.986-1.885C3622.191,1864.407,3623.873,1857.505,3623.2,1855.758Zm-34.554.442h4.951c.093,2.957.6,7.629,2.678,11.426a6,6,0,0,1-4.1-1.576C3589.3,1863.4,3587.942,1856.2,3588.649,1856.2Zm29.457,9.85a6,6,0,0,1-4.1,1.576c2.075-3.8,2.588-8.469,2.678-11.426h4.954C3622.345,1856.2,3620.989,1863.4,3618.105,1866.05Zm-7.941-4.756-2.158,1.656a.8.8,0,0,0-.265.871l.843,2.684a.751.751,0,0,1-1.168.853l-1.953-1.628a.715.715,0,0,0-.926,0l-1.953,1.628a.752.752,0,0,1-1.17-.853l.845-2.684a.8.8,0,0,0-.268-.871l-2.155-1.656a.785.785,0,0,1,.455-1.407l2.549.055a.749.749,0,0,0,.731-.564l.714-2.6a.736.736,0,0,1,1.431,0l.715,2.6a.748.748,0,0,0,.73.564l2.548-.055A.785.785,0,0,1,3610.164,1861.294Z"
			transform="translate(-3586.937 -1853.29)"
			fillRule="evenodd"
			fill="url(#linear-gradientTrophy)" />
	</svg>)
}
//#endregion