import IFilmnytt from "src/lib/types/Filmnytt";
import { IFilmomtale } from "src/lib/types/Filmomtale";
import IMovieList from "src/lib/types/MovieList";
import { SanityDocument } from "next-sanity";
import { KeyedObject, TypedObject } from "sanity";

export default interface IForsidenV2 extends SanityDocument {
    _type: "frontpage-v2";
    articleList?: (PublishableDocument | LayoutObject)[];
    mostReadNewsArticleList?: IFilmnytt[];
}

export type ThemeValues = "dark" | "light" | "lightGradient" | "gold";

const DocType_Forsiden = "frontpage-v2";

const ItemType_StreamingSearchBox = "streamingSearchBox";
const ItemType_PosterList = "posterList";
const ItemType_TopBlock = "topBlock";
const ItemType_BlockWithAd = "blockWithAd";
const ItemType_ArticleRowBlock = "articleRowBlock";
const ItemType_ArticleRowBlock24 = "articleRowBlock2-4";
const ItemType_SquareArticleRowBlock = "squareArticleRowBlock";
const ItemType_ListPosterRow = "listPosterRow";
const ItemType_VerticalArticleListGroup = "verticalArticleListGroup";
const ItemType_VerticalArticleList = "verticalArticleList";
const ItemType_MostReadNews = "mostReadNews";
const ItemType_ArticlesAndList = "articlesAndList";
const ItemType_IconRow = "iconRow";
const ItemType_OneArticleAndPopMovies = "oneArticleAndPopMovies"
const ItemType_SlimArticleBlock = "slimArticleBlock";

export const CountableItemTypes = [DocType_Forsiden,
    ItemType_ArticleRowBlock,
    ItemType_ArticleRowBlock24,
    ItemType_BlockWithAd,
    ItemType_ListPosterRow,
    ItemType_PosterList,
    ItemType_SquareArticleRowBlock,
    ItemType_StreamingSearchBox,
    ItemType_TopBlock,
    //ItemType_VerticalArticleList,
    ItemType_VerticalArticleListGroup,
    ItemType_ArticlesAndList,
    ItemType_IconRow,
    ItemType_OneArticleAndPopMovies,
    ItemType_SlimArticleBlock
];

export {
    DocType_Forsiden,
    ItemType_ArticleRowBlock,
    ItemType_ArticleRowBlock24,
    ItemType_BlockWithAd,
    ItemType_ListPosterRow,
    ItemType_PosterList,
    ItemType_SquareArticleRowBlock,
    ItemType_StreamingSearchBox,
    ItemType_TopBlock,
    ItemType_VerticalArticleList,
    ItemType_MostReadNews,
    ItemType_VerticalArticleListGroup,
    ItemType_ArticlesAndList,
    ItemType_IconRow,
    ItemType_OneArticleAndPopMovies,
    ItemType_SlimArticleBlock
};

export interface ITopBlock extends KeyedObject, TypedObject {
    _type: "topBlock";
    themes0?: ThemeValues;
    themes1?: ThemeValues;
}

export interface IOneArticleAndPopMovies extends KeyedObject, TypedObject {
    _type: "oneArticleAndPopMovies"
    linkTitle?: string;
    linkUrl?: string;
}

export interface IBlockWithAd extends KeyedObject, TypedObject {
    _type: "blockWithAd";
    themes0?: ThemeValues;
    themes1?: ThemeValues;
}

export interface IStreamingSearchBox extends KeyedObject, TypedObject {
    _type: "streamingSearchBox";
    showSgLogo?: boolean; // default to true, so if not explicitly set to false, it should show the lodo
};

export interface IPosterList extends KeyedObject, TypedObject {
    _type: "posterList";
    title: string;
    movies: PublishableDocument[];
}

export interface IArticleRowBlock extends KeyedObject, TypedObject {
    _type: "articleRowBlock";
    themes0?: ThemeValues;
    themes1?: ThemeValues;
    themes2?: ThemeValues;
}

export interface IArticleRowBlock24 extends KeyedObject, TypedObject {
    _type: "articleRowBlock2-4";
    themes0?: ThemeValues;
    themes1?: ThemeValues;
    themes2?: ThemeValues;
    themes3?: ThemeValues;
}

export interface ISquareArticleRowBlock extends KeyedObject, TypedObject {
    _type: "squareArticleRowBlock";
    themes0?: ThemeValues;
    themes1?: ThemeValues;
}

export interface IMovieListBlock extends TypedObject, KeyedObject {
    _type: "listPosterRow";
    title: string;
    extLogoUrl?: string;
    extLogoLink?: string;
}

export interface IVerticalArticleListGroup extends TypedObject, KeyedObject {
    _type: "verticalArticleListGroup"
}

export interface IVerticalArticleList extends TypedObject, KeyedObject {
    _type: "verticalArticleList";
    label: string;
    themes?: ThemeValues;
    showSeparators?: boolean;
    articles: IFilmnytt[];
}

export interface IMostReadNews extends TypedObject, KeyedObject {
    _type: "mostReadNews";
    label: string;
    themes?: ThemeValues;
    showSeparators?: boolean;
    articles: IFilmnytt[];
}

export interface ISlimArticleBlock extends TypedObject, KeyedObject {
    _type: "slimArticleBlock",
    label: string;
    themes?: ThemeValues;
    showSeparators?: boolean;
    articles: IFilmnytt[];
    linkTitle: string;
    linkUrl: string;
    linkIcon: LinkIcons;
}

export type LinkIcons = "none" | "newsWithBling";

export interface IArticlesAndListGroup extends TypedObject, KeyedObject {
    _type: "articlesAndList",
    themes0?: ThemeValues;
    themes1?: ThemeValues;
    themes2?: ThemeValues;
    themes3?: ThemeValues;
}

export interface IIconRow extends TypedObject, KeyedObject {
    _type: "iconRow";
    icons: IIconRowIcon[];
}

export interface IIconRowIcon extends TypedObject, KeyedObject {
    _type: "icon";
    svg: string;
    link: string;
    title: string;
    subtitle?: string;
}

export type LayoutObject = ITopBlock | IBlockWithAd | IStreamingSearchBox | IPosterList | IArticleRowBlock | ISquareArticleRowBlock | IMovieListBlock | IArticleRowBlock24 | IVerticalArticleListGroup | IVerticalArticleList | IArticlesAndListGroup | IMostReadNews | IIconRow | IOneArticleAndPopMovies | ISlimArticleBlock;

type GroupableLayoutObject = Exclude<LayoutObject, IStreamingSearchBox>;

export type GroupedLayoutObject<TLayout extends GroupableLayoutObject = GroupableLayoutObject> = TLayout & {
    articles?: PublishableDocument[];
}

export type GroupedLayoutObjectWithSubgroups<TLayout extends GroupableLayoutObject = GroupableLayoutObject, TSubLayout extends GroupableLayoutObject = GroupableLayoutObject> = TLayout & {
    layouts?: GroupedLayoutObject<TSubLayout>[];
};

export type GroupedLayoutObjectWithSubgroupsAndArticles<TLayout extends GroupableLayoutObject = GroupableLayoutObject, TSubLayout extends GroupableLayoutObject = GroupableLayoutObject> = GroupedLayoutObject<TLayout> & GroupedLayoutObjectWithSubgroups<TLayout, TSubLayout>;

export interface IArticlesAndList extends GroupedLayoutObjectWithSubgroupsAndArticles<IArticlesAndListGroup, IVerticalArticleList | IMostReadNews> {
    sectionTitle?: string;
}

export type PublishableDocument = IFilmnytt | IFilmomtale | IMovieList;