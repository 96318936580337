import { StandardSvgProps } from 'src/components/svg/StandardSvgProps';
import { SDefaultSvg } from 'src/components/svg/SvgStyles';

export default function PlaySvg({ className }: StandardSvgProps) {
	return (
		<SDefaultSvg className={className} viewBox="0 0 40 40">
			<path d="M20,0C9,0,0,9,0,20s9,20,20,20s20-9,20-20S31,0,20,0z
											M26.9,21.1l-10,6.2c-0.6,0.4-1.4,0.2-1.7-0.4
											c-0.1-0.2-0.2-0.4-0.2-0.7V13.8c0-0.7,0.6-1.2,1.3-1.2c0.2,0,0.5,0.1,0.7,0.2l10,6.2c0.6,0.4,0.8,1.1,0.4,1.7
											C27.2,20.8,27.1,21,26.9,21.1z"
			/>
		</SDefaultSvg>
	);
}