import { IFilmomtale } from "src/lib/types/Filmomtale";
import { Image, SanityDocument, TypedObject } from "sanity";

export const DocType_PremiumLandingPage = "premiumLandingPage";

export const Dice_Pos_above = "aboveProgram";
export const Dice_Pos_below = "belowProgram";

export default interface IPremiumLandingPage extends SanityDocument {
    _type: "premiumLandingPage";
	movie: IFilmomtale;
	isLive: boolean;
	bgVideoIdDesktop?: number;
	bgVideoIdMobile?: number;
	horizStill?: Image;
	vertStill?: Image;
	presaleStartDate?: string;
	//dicePosition?: string;
	dicePosition?: "none" | "topOfPage" | "belowTitle";
	promotedVideoId?: number;

	titleColor: Color;
	bgColor: Color;
	textColor: Color;
	buttonColor: Color;

	factsColor?: Color;
	ingressColor?: Color;
	bodytextColor?: Color;
	infolineColor?: Color;
	diceColor?: Color;
	diceTextColor?: Color;
	actionButtonsColor?: Color;
	selectorsColor?: Color;
	selectorsTextColor?: Color;
	showsColor?: Color;
	showsTextColor?: Color;

	sgProviderBg?: Color;

	kkOverrideColor?: Color;

	isNFKSupported: boolean;

	logoMobile?: ILogoImage;
	logoDesktop?: ILogoImage;
}

export interface Color extends TypedObject {
	hex: string;
}

interface ILogoImage extends TypedObject {
	logoImage: Image;
	logoWidth: number;
}