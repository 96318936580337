import type { ImageTitleProps } from 'src/components/articledisplays/ImageTitle';
import ImageTitle from 'src/components/articledisplays/ImageTitle';

//#region [Props]
type ImageTitleAspect2_1Props = Omit<ImageTitleProps, "forcedAspect">;
//#endregion

//#region [Component]
export default function ImageTitleAspect2_1(props: ImageTitleAspect2_1Props) {
	return <ImageTitle {...props} forcedAspect={ASPECT_2_1} />;
}
//#endregion

export const ASPECT_2_1 = 2 / 1;