import { StandardSvgProps } from 'src/components/svg/StandardSvgProps';

//#region [Props]
type SGClapperboardSvgProps = {
} & StandardSvgProps;
//#endregion

//#region [Component]
export default function SGClapperboard({ className }: SGClapperboardSvgProps) {
	return (<svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.388 43.374">
		<defs>
			<linearGradient id="linear-gradientClapper" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
				<stop offset="0" stopColor="var(--custom-gradient-stop0)" />
				<stop offset="1" stopColor="var(--custom-gradient-stop1)" />
			</linearGradient>
		</defs>
		<path d="M2888.774,1847.755l-2.489,1.884a.9.9,0,0,0-.306.991l.973,3.05a.864.864,0,0,1-1.35.97l-2.252-1.851a.835.835,0,0,0-1.068,0l-2.25,1.851a.864.864,0,0,1-1.349-.97l.973-3.05a.9.9,0,0,0-.309-.991l-2.487-1.884a.891.891,0,0,1,.526-1.6l2.939.064a.863.863,0,0,0,.842-.641l.826-2.96a.849.849,0,0,1,1.647,0l.826,2.96a.863.863,0,0,0,.842.641l2.939-.064a.892.892,0,0,1,.525,1.6Zm27.312-28.7q-4.128.174-4.3,4.3a.173.173,0,1,1-.345,0q-.171-4.128-4.3-4.3a.173.173,0,0,1,0-.345q4.127-.17,4.3-4.3a.173.173,0,0,1,.345,0q.169,4.132,4.3,4.3a.171.171,0,0,1,0,.342Zm-4.716,9.7a.258.258,0,0,1,0,.515q-6.174.255-6.433,6.435a.258.258,0,0,1-.515,0q-.254-6.178-6.427-6.438a.257.257,0,0,1,0-.515q6.174-.259,6.433-6.435a.257.257,0,0,1,.511,0Q2905.2,1828.5,2911.37,1828.758Zm-17.324-8.422,2.036-.546a2.371,2.371,0,0,1,2.9,1.676l.918,3.431-1.019.273ZM2882.6,1823.4l6.16-1.65,4.839,4.834-6.16,1.65Zm6.562,10.526,5.4,5.328h5.959l.008,0-5.4-5.323h5.462v21.319a2.368,2.368,0,0,1-2.37,2.368h-30.81a2.369,2.369,0,0,1-2.371-2.368v-19.594l3.655,3.6h5.972l-5.4-5.328h6.96l5.4,5.328h5.972l-5.4-5.328Zm-18.006-7.462,6.16-1.647,4.837,4.834-6.159,1.65Zm-3.74,7.462a1.778,1.778,0,1,1-2.342-1.676l-.428-1.591a2.366,2.366,0,0,1,1.259-2.741l4.8,4.8-3.321.891A1.845,1.845,0,0,1,2867.415,1833.927Z"
			transform="translate(-2863.859 -1814.24)"
			fillRule="evenodd"
			fill="url(#linear-gradientClapper)" />
	</svg>)
}
//#endregion