import { StandardSvgProps } from 'src/components/svg/StandardSvgProps';


//#region [Props]
type SGPlayStackSvgProps = {
} & StandardSvgProps;
//#endregion

//#region [Component]
export default function SGPlayStack({ className }: SGPlayStackSvgProps) {
	return (<svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.947 41.636">
		<defs>
			<linearGradient id="linear-gradientStack" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
				<stop offset="0" stopColor="var(--custom-gradient-stop0)" />
				<stop offset="1" stopColor="var(--custom-gradient-stop1)" />
			</linearGradient>
		</defs>
		<path d="M3276.253,1828.5q-6.114.255-6.37,6.372a.255.255,0,1,1-.51,0q-.252-6.117-6.364-6.375a.255.255,0,0,1,0-.51q6.113-.253,6.369-6.372a.255.255,0,0,1,.51,0q.253,6.117,6.365,6.375a.255.255,0,1,1,0,.51Zm-8.8-7.182q-4.083.172-4.256,4.259a.17.17,0,0,1-.34,0q-.172-4.087-4.253-4.262a.169.169,0,1,1,0-.339q4.083-.168,4.256-4.259a.17.17,0,0,1,.339,0q.168,4.091,4.253,4.262a.17.17,0,0,1,0,.339Zm-.746,12.209v15.823a2.336,2.336,0,0,1-1.756,2.261V1835.28a2.341,2.341,0,0,0-2.343-2.342h-34.455a2.335,2.335,0,0,1,2.259-1.759h33.953A2.343,2.343,0,0,1,3266.71,1833.524Zm-2.343,18.168a2.352,2.352,0,0,0,.586-.084v.084Zm-36.3-18.754h.084a2.355,2.355,0,0,0-.084.586Zm35.125,4.1v15.826a2.337,2.337,0,0,1-1.756,2.261V1838.8a2.343,2.343,0,0,0-2.342-2.345h-34.455a2.335,2.335,0,0,1,2.258-1.759h33.953a2.343,2.343,0,0,1,2.343,2.345Zm-2.343,18.168a2.34,2.34,0,0,0,.586-.082v.082Zm-36.295-18.754h.084a2.31,2.31,0,0,0-.084.586Zm.043,4.147a2.343,2.343,0,0,1,2.342-2.345h30.44a2.345,2.345,0,0,1,2.342,2.345v15.239a2.345,2.345,0,0,1-2.342,2.342h-30.441a2.342,2.342,0,0,1-2.342-2.342v-.632h-.043v-2.342a2.28,2.28,0,0,0,.043.423Zm13.246,12.41a.956.956,0,0,0,.469.828.86.86,0,0,0,.44.12.886.886,0,0,0,.482-.143l7.28-4.746a.969.969,0,0,0,0-1.608l-7.28-4.744a.872.872,0,0,0-.922-.025.954.954,0,0,0-.469.828Z"
			transform="translate(-3224.561 -1816.545)"
			fillRule="evenodd"
			fill="url(#linear-gradientStack)" />
	</svg>)
}
//#endregion