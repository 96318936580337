import ToolboxLink from 'src/components/streaming/streamingtoolbox/ToolboxLink';
import theme from 'src/components/themes/DefaultTheme';
import styled from 'src/lib/styles/css';
import IStreamingToolbox from 'src/lib/types/StreamingToolbox';

//#region [Props]
type ToolboxProps = {
	toolbox?: IStreamingToolbox;
    className?: string;
};
//#endregion

//#region [Component]
export default function Toolbox({ toolbox, className }: ToolboxProps) {
	if ((toolbox?.links?.length ?? 0) === 0) {
		return null;
	}
	return <SToolbox className={className}>
		{toolbox!.links!.map((link, index) => {
			return <SToolboxLink
				key={link._key as string}
				link={link}
				index={index} />
		})}
	</SToolbox>
}
//#endregion

//#region [Styles]
// NOTE: This uses scroll driven animations to detect if an item overflows
const SToolbox = styled.div`
    @keyframes detect-scroll {
        from,
        to {
            --can-scroll: 1;
        }
    }

	display: flex;
	margin-top: 2em;
	overflow-x: auto;
	gap: 15px;


	@media ${theme.mq.mobile} {
		margin-right: -10px;
	}

	@media ${theme.mq.desktop} {
        overflow-x: auto;
		//justify-content: center;

        animation: detect-scroll linear;
        animation-timeline: scroll(self inline);

        --bg-if-can-scroll: var(--can-scroll) flex-start;
        --bg-if-cant-scroll: center;
        justify-content: var(--bg-if-can-scroll, var(--bg-if-cant-scroll));
        scrollbar-width: thin;
        scrollbar-color: ${theme.palette.lilla1} var(--custom-gradient-stop0);
        scrollbar-gutter: stable;
	}
`;

const SToolboxLink = styled(ToolboxLink)`
	flex: 0 0 25vw;
	@media ${theme.mq.desktop} {
		flex: 0 0 142px;
	}
`;
//#endregion