import SGClapperboard from "src/components/svg/SGClapperboard";
import SGCompass from "src/components/svg/SGCompass";
import SGNewsPageWithBling from "src/components/svg/SGNewsPageWithBling";
import SGPlayStack from "src/components/svg/SGPlayStack";
import SGTrophy from "src/components/svg/SGTrophy";
import { StandardSvgProps } from "src/components/svg/StandardSvgProps";

import styled from "src/lib/styles/css";

//#region [Props]
type ToolboxImageProps = {
	type?: string | null;
	className?: string;
};
//#endregion

//#region [Component]
export default function ToolboxImage({ type, className }: ToolboxImageProps) {
	if (!type) {
		return null;
	}

	let ToolboxImage: React.FunctionComponent<StandardSvgProps> | undefined;
	switch (type) {
		case "compass":
			ToolboxImage = SGCompass;
			break;
		case "clapperboard":
			ToolboxImage = SSGClapperboard;
			break;
		case "playstack":
			ToolboxImage = SSGPlayStack;
			break;
		case "trophy":
			ToolboxImage = SGTrophy;
			break;
        case "newsBling":
            ToolboxImage = SSGNewsPageWithBling;
            break;

	}

	if (!ToolboxImage) {
		return null;
	}

	return (<SImageContainer className={className}>
		<ToolboxImage />
	</SImageContainer>);
}
//#endregion

//#region [Styles]
const SImageContainer = styled.div`
	aspect-ratio: 1;
	border-radius: 50%;
	background-color: var(--backgroundcolor);
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		width: 50%;
		display: block;
	}
`;

const SSGPlayStack = styled(SGPlayStack)`
	transform: translate(10%, -20%);
`;

const SSGClapperboard = styled(SGClapperboard)`
	transform: translate(13%, -15%);
`;

const SSGNewsPageWithBling = styled(SGNewsPageWithBling)`
    transform: translate(14%, -15%);
`;

//#endregion