import { Filmomtale } from "@filmweb/sanity-types";
import IFilmnytt from "src/lib/types/Filmnytt";
import { Reference, SanityDocument } from "sanity";

const DocType_Filmomtale = "filmomtale";

export { DocType_Filmomtale };

interface IFilmomtale<ReferenceIsExpanded extends boolean = boolean> extends Filmomtale {
    _type: "filmomtale";
    __typename: "filmomtale";
    otherRelations: ReferenceIsExpanded extends true ? IFilmnytt[] : Reference[];
}

export type { IFilmomtale };

export function isFilmomtale(document?: any): document is IFilmomtale {
    return document?._type === DocType_Filmomtale;
}
