import PlaySvg from "src/components/svg/PlaySvg";
import theme from "src/components/themes/DefaultTheme";
import styled from "src/lib/styles/css";
import { DocType_Filmklipp } from "src/lib/types/Filmklipp";
import IFilmnytt, { DocType_Filmnytt } from "src/lib/types/Filmnytt";
import { DocType_Trailer } from "src/lib/types/Trailer";
import { SanityDocument } from "next-sanity";

//#region [Props]
type DocumentIconProps = {
    document: SanityDocument
    className?: string;
};
//#endregion

//#region [Component]
export default function DocumentIcon({ document, className }: DocumentIconProps) {
    if (document?._type !== DocType_Filmnytt) {
        return null;
    }
    const _filmnytt = document as IFilmnytt;
    if (_filmnytt.related?.some(r => !!r && (r._type === DocType_Trailer || r._type === DocType_Filmklipp))) {
        return <SPlaySvg className={className}/>;
    }
    return null;
}
//#endregion

//#region [Styles]
const SPlaySvg = styled(PlaySvg)`
	position: absolute;
	max-width: 13%;
	right: 5%;
	top: 0;
	transform: translateY(-65%);

	@media ${theme.mq.desktop} {
		//@media (orientation: landscape) {
		max-width: 7%;
	}

`;
//#endregion