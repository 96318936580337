import { StandardSvgProps } from 'src/components/svg/StandardSvgProps';

//#region [Props]
type SGCompassSvgProps = {
} & StandardSvgProps;
//#endregion

//#region [Component]
export default function SGCompass({ className }: SGCompassSvgProps) {
	return (<svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.129 43.128">
		<defs>
			<linearGradient id="linear-gradientCompass" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
				<stop offset="0" stopColor="var(--custom-gradient-stop0)" />
				<stop offset="1" stopColor="var(--custom-gradient-stop1)" />
			</linearGradient>
		</defs>
		<path
			d="M2574.879,1836.55a21.564,21.564,0,1,1-21.539,21.564A21.564,21.564,0,0,1,2574.879,1836.55Zm.1,41.165a19.517,19.517,0,1,1,19.508-19.513,19.516,19.516,0,0,1-19.508,19.513Zm3.062-36.357-2.734,4.479-2.792-4.573a17.125,17.125,0,0,0-14.394,13.978l4.83,2.957-4.83,2.954a17.122,17.122,0,0,0,14.38,13.975l2.79-4.57,2.736,4.482a17.123,17.123,0,0,0,13.865-14.253l-4.233-2.592,4.233-2.589A17.119,17.119,0,0,0,2578.037,1841.357Zm6.248,26.168L2572.6,1860.4l-7.12-11.695,11.689,7.125Zm-11.081-11.044a2.125,2.125,0,0,0,0,3c.042.042.085.079.127.116h0l0,0a2.125,2.125,0,0,0,.572.348l7.19,4.383-4.734-7.767-.045.045c-.036-.042-.073-.085-.113-.127a2.12,2.12,0,0,0-3,0Zm2.527,2.456a1.348,1.348,0,1,1,.4-.957A1.352,1.352,0,0,1,2575.73,1858.938Z"
			transform="translate(-2553.34 -1836.55)"
			fillRule="evenodd"
			fill="url(#linear-gradientCompass)" />
	</svg>)
}
//#endregion