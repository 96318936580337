import { ICustomImage } from "@filmweb/sanity-types";
import IFilmnytt, { DocType_Filmnytt } from "src/lib/types/Filmnytt";
import { DocType_Filmomtale, IFilmomtale } from "src/lib/types/Filmomtale";
import { DocType_Forsiden as DocType_Forsiden_v2 } from "src/lib/types/IForsidenV2";

import { DocType_PremiumLandingPage } from "src/lib/types/IPremiumLandingPage";
import { SanityDocument } from "sanity";

/**
 * Creates an url for a given document.
 * NOTE: Only document with an actual route should be represented here!
 * @param docType The document type
 * @param slug the document's slug
 * @returns the url or null if the document type is not supported
 */
export function getUrlForDoctype(docType: string, slug: string, isStreamingContext: boolean = false, document?: SanityDocument): string | null {
	switch (docType) {
		case DocType_Filmnytt:
			if ((document?.extLink as string)?.trim()) {
				return (document?.extLink as string)?.trim();
			}
			return `/${isStreamingContext ? 'streamingguide/artikkel' : 'filmnytt'}/${slug}`
		case DocType_Filmomtale:
			return `/film/${slug}`;
		case DocType_Forsiden_v2:
			if (slug.endsWith("frontpage_v2")) {
				return "/";
			} else if (slug.endsWith("streamingguide_v2")) {
				return "/streamingguide";

			} else if (slug.endsWith("filmnytt_v1")) {
				return "/filmnytt"
			}
		case DocType_PremiumLandingPage:
			return `/program/${slug}`
		default:
			console.error(`Unknown ${docType} / Slug: ${slug} / SG Context: ${isStreamingContext} `);
	}
	return null;
}

/**
 * Helper function to get an article's Image
 * @param document the document
 * @returns the image or undefined if no image is available
 */
export function getImage(document: IFilmnytt | IFilmomtale): ICustomImage | undefined {
	switch (document._type) {
		case DocType_Filmnytt:
			if (Array.isArray((document as IFilmnytt).imageV2)) {
				return (document as IFilmnytt).imageV2?.[0] as ICustomImage | undefined;
			} else {
				return (document as IFilmnytt).imageV2 as ICustomImage | undefined;
			}

		case DocType_Filmomtale:
			const filmomtale = (document as IFilmomtale);
			return (filmomtale.mainImageV2 ?? filmomtale.imagesV2?.[0]) as ICustomImage | undefined;

	}
}