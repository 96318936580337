import theme from "src/components/themes/DefaultTheme";
import { css } from "src/lib/styles/css";

const CSSAdContentLabelBase = css`
    content: "Annonsørinnhold";
    display: block;

    font-weight: bold;
    z-index: 1;

    line-height: 1;
`;

export const CSSAdContentLabelAfter = css`
    &::after {
        ${CSSAdContentLabelBase}
        text-align: center;
        padding: 0.5em 0;
        font-size: 10px;
        color: currentColor;
    }
`

const CSSAdContentLabel = css`
	&::before {
		${CSSAdContentLabelBase}
        color: #8fddfd;
        border-radius: 8px;
        padding: 8px 11px;
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 14px;
        background-color: var(--BACKGROUNDCOLOR_OVERRIDE, var(--buttoncolor));

		@media ${theme.mq.desktop} {
			//@media (orientation: landscape) {
			font-size: 18px;
			padding: 10px 15px;
			top: 20px;
			right: 20px;
		}
	}
`;

export default CSSAdContentLabel