import { type ICustomImage } from "@filmweb/sanity-types";
import { type SanityDocument } from "sanity";


const DocType_Filmklipp = "filmklipp";

export { DocType_Filmklipp };

export default interface IFilmklipp extends SanityDocument {
    _type: "filmklipp";
	title: string;
	clipText?: string;
	videoId: string;
	imageV2?: ICustomImage;
	createdAt?: string;
	updatedAt?: string;
}